<script setup lang="ts">
import { vElementVisibility } from "@vueuse/components";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import VStack from "@magnit/core/src/components/VStack/VStack.vue";
import VStackItem from "@magnit/core/src/components/VStack/VStackItem.vue";
import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";
import { IYaSlotApiStatus, useSlotsStore } from "~/stores/useSlotsStore";

const APP_BANNER_SLOT_CONFIG: IYaSlotConfig[] = [
  {
    id: "adfox_170714033303537862",
    p1: "dayiu",
    p2: "htnq",
    to: "m",
  },
  {
    id: "adfox_17071404527478762",
    p1: "dayiv",
    p2: "htnq",
    from: "m",
    to: "ml",
  },
  {
    id: "adfox_170714052908255437",
    p1: "dayiw",
    p2: "htnq",
    from: "ml",
    to: "l",
  },
  {
    id: "adfox_170714055568938210",
    p1: "dayix",
    p2: "htnq",
    from: "l",
    to: "xl",
  },
  {
    id: "adfox_170714057983624274",
    p1: "dayiy",
    p2: "htnq",
    from: "xl",
  },
];

const { send } = useAnalytics();
const { adfoxOwnerId } = usePublicConfig();
const slotApi = useSlotsStore();
const stub = ref(false);
const adfoxLoaded = computed(() =>
  [IYaSlotApiStatus.SUCCESS, IYaSlotApiStatus.ERROR].includes(slotApi.slotStatus),
);
const onVisibility = (v: boolean) => v && send("Main:InstallBanner:View");
const onClick = () => send("Main:InstallBanner:Click");
</script>

<template>
  <ClientOnly>
    <SectionsMobileAppBannerSkeleton v-if="!adfoxLoaded || !slotApi.slotApiLoaded" />
  </ClientOnly>
  <section v-if="!stub" v-element-visibility="onVisibility" class="mobile-app-banner">
    <VWrapperLocal>
      <VStack>
        <VStackItem class="mobile-app-banner__inner">
          <VYaSlot
            :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
            :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
            :owner-id="adfoxOwnerId"
            :config="APP_BANNER_SLOT_CONFIG"
            with-hover
            @click="onClick"
            @stub="stub = true"
          />
        </VStackItem>
      </VStack>
    </VWrapperLocal>
  </section>
</template>

<style scoped lang="postcss">
.mobile-app-banner {
  margin-top: 48px;

  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 529px;

  :deep(.unit-ad-block-message) {
    min-height: 529px;
  }

  @media (--pl-viewport-from-s) {
    min-height: 568px;

    :deep(.unit-ad-block-message) {
      min-height: 568px;
    }
  }

  @media (--pl-viewport-from-m) {
    margin-top: 56px;
    min-height: 476px;

    :deep(.unit-ad-block-message) {
      min-height: 476px;
    }
  }

  @media (--pl-viewport-from-l) {
    margin-top: 64px;
    min-height: 544px;

    :deep(.unit-ad-block-message) {
      min-height: 544px;
    }
  }

  &__inner {
    overflow: hidden;
    border-radius: var(--pl-unit-x5);
    isolation: isolate;

    @media (--pl-viewport-from-l) {
      border-radius: var(--pl-unit-x8);
    }
  }
}
</style>
